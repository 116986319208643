<template>
	<div>
		<div class="text-right mg-r-1 mg-t-4 fix-top-right">
			<router-link to="/join">
				<el-button  class="mg-y-5 " type="primary" :loading="loadingNext" plain round>Join room</el-button>
			</router-link>
		</div>
		<DefaultTemplate>
			<h1 class="text-center">Sign In</h1>
			<el-form label-position="top" :model="form" :rules="rules" ref="ruleForm" label-width="120px">
				
				<el-alert
					v-if="msgErrorValid !== ''"
					title="แจ้งเตือน"
					type="error"
					:closable="false"
					:class="{'shake animated': animated}"
					:description="msgErrorValid"
					show-icon>
				</el-alert>
				<el-form-item label="Email" prop="username">
					<el-input @keyup.enter.native="submit" class="rounded" v-model="form.username"></el-input>
				</el-form-item>
				<el-form-item label="Password" prop="pass">
					<el-input type="password" :show-password="true" class="rounded" v-model="form.pass" @keyup.enter.native="submit"></el-input>
				</el-form-item>
			</el-form>
			<el-button  class="mg-y-5 w-100" type="primary" @click="submit()" :loading="loadingNext" round>Sign in</el-button>
			<!-- <button class="mg-y-5 w-100 btn-primary" @click="submit()" type="submit" round>Sign in</button> -->
			<p class="text-center"><router-link to="/sign-up">สมัครสมาชิก</router-link></p>
			
		</DefaultTemplate>		
	</div>
			
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	components:{
		DefaultTemplate
	},
	data(){
		return{
			loadingNext:false,
			animated:true,
			msgErrorValid:'',
			form:{
				username:'',
				pass:''
			},
			rules: {
				username: [
					{ required: true, message: 'Please input email address', trigger: 'blur' },
					{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
				],
				pass: [
					{ required: true, message: 'Please input password', trigger: 'blur' },
					{ min: 8, message: 'Password must be at least 8 characters', trigger: 'blur' }
				],  
        	}
		}
	},
	computed:{
		user(){
			return this.$store.state.user
		}
	},
	mounted(){
		this.$store.commit('SIGNOUT')
	},
	methods:{
		submit(){
			this.loadingNext = true
			this.animated = false
			this.$refs['ruleForm'].validate((valid) => {
          		if (valid) {
          			HTTP.post('/login',this.form).then(async (res)=>{
          				if(res.data.success){

          					this.$store.commit('SET_USER',res.data.obj)	
          						let dataValue ={
          							label:'userId',
          							value:res.data.obj._id
          						}
          						this.$store.commit('SET_DATAUSER',dataValue)	
          					if(res.data.obj.userLevel == 0){
          						this.$router.push('/select-mode').catch(err => {})
          					}else if(res.data.obj.userLevel == 80){
          						this.$router.push('/create-room').catch(err => {})
          					}
          					
          				}else{
          					this.animated = true
          					this.msgErrorValid = 'รหัสผ่านหรืออีเมลไม่ถูกต้อง'
          				}
          			}).finally(()=>{
						this.loadingNext = false
					}).catch(e=>{
          				console.log('/login error',e)
          				this.alertFailError()
          			})
				}else{
					this.loadingNext = false
				}
			})
		}
	}
}
</script>